:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: 'Avenir';
  }



  .btn--primary {
    background-color: #FFF;
    color: #242424;  /* #242424 */
    border: 1px solid #fff;
  }

  .btn--contact {
    background-color: #FFF;
    color: #242424;  /* #242424 */
    border: none;

  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  /* .btn--medium-contact {
    padding: 8px 20px;
    font-size: 18px;
  } */

  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }

  
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover{
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }


  .btn--medium:hover,
  .btn--primary:hover {
    transition: all 0.3s ease-out;
    background: #242424;
    color: #fff;
    border: 1px solid transparent;
    transition: 250ms;
  }

  .btn--contact:hover {
    transition: all 0.3s ease-out;
    background: red;
    /* color: #fff; */
    border: 1px solid red;
    transition: 250ms;
  }
  

