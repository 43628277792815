/* STYLE 1 - MERGED PICTURES */
.galerie-container {
/* margin: TOP RIGHT BOTTOM LEFT */
margin: 0px ;
align-items: center;
align-content: center;
justify-Content: 'center';
}


.galerie {
  padding : 50px;
  background: white;
  border: 200px;
}

.zone-click-screen--right{
  position:absolute;
  background: transparent;
  height: 90vh;
  width: 50vw;
  z-index: 99;
  right: 0;
}

.zone-click-screen--left {
  position:absolute;
  background: transparent;
  height: 90vh;
  width: 50vw;
  z-index: 99;
  left: 0;
}

.image_display_background {
  z-index: 10;
}

.image_item {
  overflow: hidden;
  /* height : 500px; */
  /* position: relative; */
  /* width : auto; */
  /* display: block; */
 
}

.image_item img {
  /* LES DEUX PROCHAINES LIGNES FONT BUGER LE DISPLAY */
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  width: 100%;
  display: block;
  cursor: zoom-in;
} 


.image_display_background {
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
 /* z-index: 2; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.parent {
  position: absolute;
  height: auto;
  width: auto;
  padding: 10px;
  /* background-color: brown; */
  /* margin-Top: -80px; */
  /* top: 80px; */
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 10;
  /* text-align: right; */
  /* align-items: flex-end; */
  /* text-align: end; */
}

.child1 {
  /* background-color: green; */
  position: relative;
  padding: 10px;
}

.child2 {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 11;
  right: 50px;
bottom: 50px;
  /* margin-right: -20px; */
  /* background-color: orange; */
  align-items: flex-end;
}

.child2 > img {
  width: 100%;
  opacity: 0.4;
}


.image_item:hover img {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
} 

/* 

.style1 {
width: 100%;
height: 100vh;
background: 'black';
position: fixed;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
}

.style1 > img {
width: auto;
max-width: 90%;
max-height: 90%;
} */



/*
.photo-list {
    width: 90%;
    height: 100vh;
    list-style: none;
    margin: 20px auto;
    background-color: green;
}
 
.photo-item {
    display: flex;
    width: 20vw;
    list-style : none;
    /* box-shadow: 0 5px 10px rgba(110, 13, 13, 1); 
    margin: 20px 20px;
    background-color: orange;
}
 
.photo-item img {
    display: flex;
    width: 19vw;
    height: auto;
    border: 4px double rgba(0, 0, 0, .12);
}

*/


.photo-list {
    width: 90%;
    list-style: none;
    margin: 20px auto;
}
 
.photo-item {
    display: flex;
    width: 300px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .12);
    margin: 20px 0;
}
 
.photo-item img {
    display: flex;
    width: 100%;
    border: 4px double rgba(0, 0, 0, .12);
}



 /* STYLE 2 - VERTICAL AND HOMOGENEOUS PICTURES */

/* .photo-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  gap: 10px;
}

.photo-item {
  display : flex;
  flex-basis: 350px; 
  height : 700px;
}

.photo-item img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: 5px;
} */








 /*STYLE 3 - 3 columns 

 /* ======================================
Responsive Image gallery Style rules
======================================*/
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .container {
    padding: 40px 5%;
  }
  
  .heading-text {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  
  .heading-text span {
    font-weight: 100;
  }
  
  /* Responsive image gallery rules begin
  
  .photo_list {
    /* Mobile first 
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .photo_list .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .photo_item img {
    width: 100%;
    border-radius: 5px;
    height: 100%;
    object-fit: cover;
  }
  
  @media only screen and (min-width: 768px) {
    .image-gallery {
      flex-direction: row;
    }
  }
  
  /* overlay styles 
  
  .image-item {
    position: relative;
    cursor: pointer;
  }
  
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(57, 57, 57, 0.502);
    top: 0;
    left: 0;
    transform: scale(0);
    transition: all 0.2s 0.1s ease-in-out;
    color: #fff;
    /* center overlay content 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* hover 
  .image-item:hover .overlay {
    transform: scale(1);
  }
   */



