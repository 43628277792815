

.flag_container {
    position: fixed;
        display: flex;
        height: 2px;
        width: 100%;
        /* flex-wrap: wrap; */
        /* height: 400px; */
        /* align-content: space-between; */
}

.left_flag {
    width: 35vw;
    height: 100%;
}

.middle_flag {
    width: 30vw;
    height: 100%;
}

.right_flag {
    width: 35vw;
    height: 100%;
}