video {
  object-fit: cover;
  width : 100%;
  height: 100vh;
  /* z-index: -1; */
  margin-top: -100px;
}



.banner-container {
  background: #242424;
  height: 90vh;
  width: 100%;
  position: relative;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  /* object-fit: contain; */
  /* margin-bottom: -100px; */
  /* margin-top: -115px; */
}

.banner-container > img {
  position: absolute;
  left: 0;
  top: 0;
  /* position: relative; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  overflow: hidden; 

  object-fit: cover;
  
  /* z-index: -1; */
  /* margin-top: -40%; */
  /* position: fixed; */
}




.banner-container > h1 {
  color: #fff;
  font-size: 100px;
  z-index: 0;
  position: absolute;
  margin-top: -10vh; 
  font-family: 'Times';
}

.banner-container > p1 {
  margin-top: 100px;
  color: #fff;
  font-size: 32px;
  z-index: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif; 
}

.banner-btns {
  margin-top: 32px;
  z-index: 0;
}

.banner-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .banner-container > h1 {
    font-size: 70px;
    /* margin-top: -520px; */
  }

  .banner-container > img {
    /* margin-top: -35vh; */
  }
}

@media screen and (max-width: 768px) {
  .banner-container > h1 {
    font-size: 50px;
    /* margin-top: -500px; */
  }

  .banner-container > p1 {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}



@media screen and (max-width: 460px) {
  .banner-container > p1 {
    font-size: 20px;
  }
  
}
